<template>
  <!-- eslint-disable -->
  <div class="page d-flex flex-row flex-column-fluid">
    <div class="wrapper d-flex flex-column flex-row-fluid">
      <div class="toolbar py-5 py-lg-15">
        <div class="container-xxl d-flex flex-stack flex-wrap">
          <h3 class="text-white fw-bolder fs-2qx me-5">Konto Klienta API</h3>
          <router-link to="/admin/organisations" class="btn btn-primary float-end">Powrót do strefy</router-link>
        </div>
      </div>
      <div class="d-flex flex-column-fluid align-items-start container-xxl">
        <div class="content flex-row-fluid">
          <div class="d-flex flex-column flex-xl-row">
            <Sidebar :id="id" />
            <UsersAdd :id="id" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from '@/components/Sidebar.vue';
import UsersAdd from '@/views/UsersAdd.vue';
export default {
  name: 'AdminUsersAdd',
  inject: ['$profile'],
  props: ['id', 'userId'],
  data() {
    return {
      organisation: false
    }
  },
  provide() {
    return {
      $organisation: () => this.organisation,
    }
  },
  mounted () {
    this.timer = setInterval(this.updateOrganisation, 60000);
    this.updateOrganisation();
  },
  methods: {
    updateOrganisation: function() {
      var self = this;
      self.$root.$refs.api.get('organisations/' + this.id).then(function (response) {
        if (typeof response.data !== 'undefined')
        {
          self.organisation = response.data;
        }
      })
      .catch(function () {
        self.organisation = {};
      });
    },
    cancelAutoUpdate() {
      clearInterval(this.timer);
    },
  },
  beforeUnmount() {
    this.cancelAutoUpdate();
  },
  computed: {
    profile() {
      return this.$profile();
    }
  },
  components: {
    Sidebar,
    UsersAdd
  }
};
</script>

<style>
ul.scopes {
  list-style-type: none;
  padding: 0;
}
ul.scopes li {
  padding: 0;
}
</style>
